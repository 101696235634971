<template>
  <div class="login-container" >
    <div class="background_box">
      <div class="right_form">
        <div class="leftImg">
          <img src="../../../src/assets/login_left22.png" alt="" srcset="" style="width: 96%;margin-top: 34%;">
        </div>
        <div style="float: left;position:relative;margin-left: 7%;margin-top: 3%;">
          <el-form ref="loginForm" :model="loginForm" :rules="loginRules" class="login-form" auto-complete="on" label-position="left">
            <div class="title-container">
              <h3 class="title">欢迎使用会计自助实操实训平台
                <span style="display:inline-block;float:right;cursor: pointer;font-size: 15px;margin-top: 5px;" id="copy" @click="copy()">
                <el-icon><DocumentCopy /></el-icon>
                分享</span>
              </h3>
            </div>

            <el-form-item prop="username">
              <span class="svg-container">
                <el-icon><User /></el-icon>
              </span>
              <el-input
                v-model="loginForm.username"
                placeholder="用户名"
                name="username"
                type="text"
                class="tips inputDeep"
                auto-complete="on"
                @keyup.enter.native="onShow('blockPuzzle')" />
            </el-form-item>

            <el-form-item prop="password">
              <span class="svg-container">
                <el-icon><Lock /></el-icon>
              </span>
              <el-input
                type="password"
                v-model="loginForm.password"
                placeholder="密码"
                name="password"
                class="inputDeep"
                @keyup.enter.native="onShow('blockPuzzle')" show-password/>
            </el-form-item>

            <!-- <el-form-item style="background: #fff;margin-bottom:10px !important;" prop="yzm">
              <img @click="getyzm" :src="img" alt="">
              <input v-model="loginForm.captchaValue" style="border:1px solid lightgray;line-height: 27px;border-radius: 3px;position: relative;top: -12px;" type="text" name="" id="" @keyup.enter="handleLogin">
            </el-form-item> -->

            <el-button size="small" :loading="loading" type="primary" class="login_btn" @click.native.prevent="onShow('blockPuzzle')">登 录</el-button>
            <!-- <el-button size="small" :loading="loading" type="primary" class="login_btn" @click.native.prevent="onShow('clickWord')">登 录2</el-button> -->

          </el-form>
          <div>
            <el-checkbox v-model="checked">记住密码</el-checkbox>
            <span @click="dialogVisibleFindPass = true" style="diaplay:inline-block;position: relative;right: -168px;color: #409eff;font-size: 14px;">忘记密码?</span>
          </div>
          <div style="font-size: 14px;margin-top: 20px;">没有账号?去<span style="color:#409eff;cursor: pointer;" @click="dialogVisible = true">注册</span></div>
        </div>
      </div>
    </div>
  </div>
  <!-- 注册 -->
  <el-dialog :close-on-click-modal="false"
    title="注册"
    v-model="dialogVisible"
    width="450px">
    <el-form ref="form" label-width="80px" size="small">
      <el-form-item label="手机号">
        <el-input v-model="form.userName" style="width: 160px;"></el-input>
        <el-button size="small" v-if="count" type="primary" style="margin-left:10px">{{count}}</el-button>
        <el-button size="small" v-else type="primary" @click="getYzm('register')" style="margin-left:10px">{{text}}</el-button>
      </el-form-item>
      <el-form-item label="验证码">
        <el-input v-model="form.yzm" style="width: 160px;"></el-input>
      </el-form-item>
    </el-form>

    <template #footer>
      <span class="dialog-footer">
        <el-button size="small" @click="dialogVisible = false">取消</el-button>
        <el-button size="small" type="primary" @click="remind">确定</el-button>
      </span>
    </template>
  </el-dialog>



  <!-- 找回密码 -->
  <el-dialog :close-on-click-modal="false"
    title="找回密码"
    v-model="dialogVisibleFindPass"
    width="450px">
    <el-form ref="form" label-width="80px" size="small">
      <el-form-item label="手机号">
        <el-input v-model="findPassParam.userName" style="width: 160px;"></el-input>

        <el-button size="small" v-if="count" type="primary" style="margin-left:10px">{{count}}</el-button>
        <el-button size="small" v-else type="primary" @click="getYzm('find')" style="margin-left:10px">{{text}}</el-button>
      </el-form-item>
      <el-form-item label="验证码">
        <el-input v-model="findPassParam.yzm" style="width: 160px;"></el-input>
      </el-form-item>
    </el-form>

    <template #footer>
      <span class="dialog-footer">
        <el-button size="small" @click="dialogVisibleFindPass = false">取消</el-button>
        <el-button size="small" type="primary" @click="findPassWord">确定</el-button>
      </span>
    </template>
  </el-dialog>

  <Verify
      mode="pop"
      :captchaType="captchaType"
      :loginForm="loginForm"
      :imgSize="{width:'400px',height:'200px'}"
      ref="verify"
    ></Verify>
</template>

<script>
import { escape, unescape } from 'lodash'
import { getCaptcha } from "@/api/public"
import Verify from '@/components/verifition/Verify'

import { findPass, sendSms, registerRemind } from "@/api/login"
export default {
  name: "login",
  components: {
    Verify
  },
  data () {
    return {
      //找回密码
      dialogVisibleFindPass: false,
      findPassParam:{
        userName:'',
        yzm:''
      },
      count: 0,
      text: "获取验证码",

      passwordType: 'password',
      img: "",
      loginForm: {
        username: '',
        password: '',
        remember: '',
        captchaId: '',
        captchaValue: '',
      },
      form:{
        userName:'',
        yzm:''
      },
      mes:'https://edu.jsptax.net/',
      checked: true,
      dialogVisible:false,
      captchaType:''
    }
  },
  created(){
    this.getyzm()
  },
  mounted() {
    this.getCookie()
  },
  methods: {
    getCode(){
      const TIME_COUNT = 60;
      if (!this.timer) {
        this.count = TIME_COUNT;
        this.show = false;
        this.timer = setInterval(() => {
        if (this.count > 0 && this.count <= TIME_COUNT) {
          this.count--;
          } else {
          this.show = true;
          clearInterval(this.timer);
          this.timer = null;
          }
        }, 1000)
      }
    },
    getYzm(type){
      let tel = ''
      if(type == "find"){
        tel = this.findPassParam.userName
      } else {
        tel = this.form.userName
      }

      if(!(/^1[3456789]\d{9}$/.test(tel))){
        this.$message({
          type: 'info',
          message: '请输入正确手机号'
        }); 
        return
      }
      this.getCode()
      sendSms({"mobile": tel}).then(res=>{
        if(res.data.msg != "success"){
          return
        }
        this.$message({
          message: '发送成功',
          type: 'success'
        })
      })
    },
    findPassWord(){
      findPass(this.findPassParam).then(res=>{
        if(res.data.msg == "success"){
          this.dialogVisibleFindPass = false
          this.$alertMsg("您的新密码是：" + res.data.data.pass)
        }
      })
    },
    remind(){
      registerRemind(this.form).then(res=>{
        if(res.data.msg == "success"){
          this.dialogVisible = false
          this.$alertMsg("您已成功注册，工作人员会尽快和您联系。")
        }
      })
    },
    onShow(type){
      this.captchaType = type
      this.$refs.verify.show(this.loginForm)
    },
    getyzm(){
      // getCaptcha({}).then(res=>{
      //   this.img = res.data.data.data.data
      //   this.loginForm.captchaId = res.data.data.data.captchaId
      // })
    },
    handleLogin(){
      this.$store.dispatch('user/LoginByUsername', this.loginForm).then(() => {
        // this.$router.push({ path: this.redirect || '/' })
        if (this.checked === true) {
            this.setCookie(this.loginForm.username, this.loginForm.password, true, 7)
          } else {
            this.clearCookie()
          }
        let time = new Date().getTime();
        localStorage.setItem("time", time);
        this.$router.push({ path: this.redirect || '/' })
      })
      .catch((err) => {
        this.getyzm()
        this.$message.error(err)
      })
    },
    setCookie (c_name, c_pwd, c_state, exdays) {
      // console.log(c_name,c_pwd,c_state);
      const exdate = new Date()
      var c_name_user = window.btoa(unescape(encodeURIComponent(c_name)))
      var c_pwd_user = window.btoa(unescape(encodeURIComponent(c_pwd)))
      exdate.setTime(exdate.getTime() + 24 * 60 * 60 * 1000 * exdays) // 保存的天数
      window.document.cookie = 'username' + '=' + c_name_user + ';path=/;expires=' + exdate.toGMTString()
      window.document.cookie = 'password' + '=' + c_pwd_user + ';path=/;expires=' + exdate.toGMTString()
      window.document.cookie = 'state' + '=' + c_state + ';path=/;expires=' + exdate.toGMTString()
    },
    getCookie () {
      if (document.cookie.length > 0) {
        const arr = document.cookie.split('; ')
        for (let i = 0; i < arr.length; i++) {
          const arr2 = arr[i].split('=')
          if (arr2[0] === 'username') {
            this.loginForm.username = decodeURIComponent(escape(window.atob(arr2[1]))) 
          } else if (arr2[0] === 'password') {
            this.loginForm.password = decodeURIComponent(escape(window.atob(arr2[1]))) 
          } else if (arr2[0] === 'state') {
            this.checked = Boolean(arr2[1])
          }
        }
      }
    },
    // 清除cookie
    clearCookie: function () {
      this.setCookie('', '', false, -1)
    },
    copy() {
      //创建input标签
      var input = document.createElement('input')
      //将input的值设置为需要复制的内容
      input.value = this.mes;
      //添加input标签
      document.body.appendChild(input)
      //选中input标签
      input.select()
      //执行复制
      document.execCommand('copy')
      //成功提示信息
      this.$message.success('复制成功！')
      //移除input标签
      document.body.removeChild(input) 
    },
    register(){
      this.dialogVisible = false
    }
  },
  setup() {
    return{
      
    }    
  },
}
</script>



<style rel="stylesheet/scss" lang="scss">

  $bg:#eee;
  $light_gray:#666;
  $cursor: #999;
  @supports (-webkit-mask: none) and (not (cater-color: $cursor)) {
    .login-container .el-input input{
      color: $cursor;
      &::first-line {
        color: $light_gray;
      }
    }
  }

  /* reset element-ui css */
  .login-container {
    width: 100%;
    height: 100%;
    .background_box{
      position: relative;
      min-height: 100%;
      width: 100%;
      height: 100%;
      background-color: rgb(250, 250, 250);
      background-size: 100%;
      overflow: hidden;
      position: relative;
      z-index: 2;
      padding-bottom: 50px;
      .leftImg{
        float: left;
        height: 100%;
        width: 45%;
        background: #3c6ff2;
        text-align: center;
        border-right: 1px solid lightgray;
      }
      .right_form{
        background-color: #fff;
        position: absolute;
        border-radius: 3px;
        width: 800px;
        z-index: 20;
        height: 520px;
        max-width: 100%;
        margin: auto;
        top: 0;
        bottom: 100px;
        left: 0;
        right: 5px;
        overflow: hidden;
        box-shadow: 0 2px 10px #ddd;
        .login_btn{
          width:100%;
          margin-bottom:20px;
          border-radius:3px !important;
          line-height: 32px !important;
          height: 44px !important;
          font-size: 21px;
        }
      }
    }
    .el-input {
      display: inline-block;
      height: 36px;
      width: 82%;
      input {
        background: transparent;
        border: 0px;
        -webkit-appearance: none;
        border-radius: 0px;
        padding: 0 10px;
        color: #333;
        height: 33px;
        line-height: 36px;
        padding: 0 !important;
        caret-color: $cursor;
        &:-webkit-autofill {
          -webkit-box-shadow: 0 0 0px 1000px $bg inset !important;
          -webkit-text-fill-color: $cursor !important;
        }
      }
    }
    .el-form-item {
      border: 1px solid rgba(255, 255, 255, 0.1);
      background: #eee;
      border-radius: 3px;
      color: #454545;
      display: flex;
      flex-direction:row;
      margin-bottom: 30px !important;
    }
  }
</style>

<style rel="stylesheet/scss" lang="scss" scoped>
$bg:#2d3a4b;
$dark_gray:#889aa4;
$light_gray:#eee;

.login-container {
  position: fixed;
  height: 100%;
  width: 100%;
  background-color: $bg;
  .login-form {
    width: 110%;
    margin: 0 auto;
    margin-top: 56px;

  }
  .tips {
    font-size: 14px;
    color: #666;
    // margin-bottom: 10px;
    span {
      &:first-of-type {
        margin-right: 16px;
      }
    }
  }
  .svg-container {
    padding: 0 5px 0 15px;
    color: $dark_gray;
    vertical-align: middle;
    width: 30px;
    // display: inline-block;
    font-size: 17px;
  }
  .title-container {
    position: relative;
    .title {
      font-size: 20px;
      margin: 0px auto 40px auto;
      text-align: left;
    }
    .title2 {
      font-size: 26px;
      color: $color-main  ;
      margin: 0px auto 40px auto;
      text-align: left;
      font-weight: bold;
    }
    .set-language {
      color: #fff;
      position: absolute;
      top: 5px;
      right: 0px;
    }
  }
  .show-pwd {
    position: absolute;
    right: 10px;
    top: 7px;
    font-size: 16px;
    color: $dark_gray;
    cursor: pointer;
    user-select: none;
  }
  .thirdparty-button {
    position: absolute;
    right: 35px;
    bottom: 28px;
  }
  .inputDeep {
    :deep(.el-input__wrapper) {
      box-shadow: none;
      cursor: default;
      background-color: #eeeeee;
      .el-input__inner {
        cursor: default !important;
      }
    }
  }
}
</style>
